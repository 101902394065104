import * as React from "react"
import WufooForm from "react-wufoo-embed"

import Layout from "../components/layout"
import Seo from "../components/seo"
import "../scss/pages/contact-us.scss"


class ContactPage extends React.Component {
  // constructor(props) {
  //   super(props)
  //   this.state = {
  //     isThankYouPage: false,
  //   }
  // }

  // componentDidMount() {
  //   // Checking url for thank you page content
  //   const checkUrl = (window.location.href.indexOf("#thank-you") > -1) ? true : false;
  //   if(checkUrl) {
  //     this.setState(state => ({
  //       isThankYouPage: true
  //     }))
  //   }
  // }

  render() {
    return (
      // <Layout bodyClass={`contact ${this.state.isThankYouPage ? 'thank-you' : ''}`}>
      <Layout bodyClass="contact">
        <Seo title="Contact Us" description="Contact our sales team for all inquiries related to SIMPAS and SIMPAS-Applied Solutions (SaS)." />
        <section className="bg--black">
          <div className="container">
            <div className="text-center flow">
              <h1>Contact Us</h1>
              <p className="text--white">
                Do you have questions about SIMPAS&#8209;applied&nbsp;Solutions&trade; 
                or the SIMPAS<sup>&reg;</sup> application system?
              </p>
              <p className="text--white">
                Connect with a SIMPAS-applied Solutions Specialist to learn&nbsp;more.
              </p>
            </div>
          </div>
        </section>
        <section className="bg--white contact-content">
          <div className="bg--shadow container--width-sm padding--tb padding--lr radius--md flow">
            <p className="text-center">
              Fill out the form below, and we’ll get back to you within 2 business days.
              <br />
              <small>*required fields</small>
            </p>
            <WufooForm
              userName="archermalmo"
              formHash="q1bsqjis190pzyb"
              header="hide"
            />
          </div>
        </section>
        {/* <section className="bg--white thank-you-content" id="thank-you">
          <div className="container bg--blue bg--shadow padding--tb padding--lr radius--md flow">
            <p className="thank-you-heading text-center">Thanks for your interest in SIMPAS-applied Solutions™ and the SIMPAS<sup>®</sup> application system.</p>
            <p className="thank-you-text text-center">We'll be in touch within 2 business days to answer your questions.</p>
          </div>
        </section> */}
      </Layout>
    )
  }
}

export default ContactPage
